.TribeHeaderLogo {
    padding: 1rem 3rem;
}

.TribeHeaderLogo .Logo {
    width: 100px;
    height: 100px;
}

.TribeHeaderLogo .Logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
