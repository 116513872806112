.TopBanner {
    position: relative;
    width: 100vw;
    height: 70vh;
}

.TopBanner .banner-bgImg {
    width: 100%;
    height: 100%;
}

.TopBanner .banner-bgImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 10%;
}

.TopBanner .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70vh;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    z-index: 1;
}

.TopBanner .wrap-icons {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    display: flex;
}

.TopBanner .wrap-icons .icon-link {
    text-decoration: none !important;
}

.TopBanner .wrap-icons .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TopBanner .wrap-icons .icon:first-child {
    margin-right: 30px;
}

.TopBanner .wrap-icons .icon:last-child {
    margin-left: 30px;
}

.TopBanner .wrap-icons .icon .wrap-img {
    width: 100px;
    height: 100px;
}

.TopBanner .wrap-icons .icon .wrap-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.TopBanner .wrap-icons .icon .text {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--halfWhite);
}

@media all and (max-width: 750px) {
    .TopBanner .wrap-icons .icon .text {
        min-width: 150px;
        text-align: center;
    }

    .TopBanner .wrap-icons .icon:first-child {
        margin-right: 10px;
    }

    .TopBanner .wrap-icons .icon:last-child {
        margin-left: 10px;
    }
}

@media all and (max-width: 550px) {
    .TopBanner .wrap-icons {
        left: 0%;
        transform: translateX(0%);
        width: 98%;
        display: flex;
        justify-content: space-around;
    }

    .TopBanner .wrap-icons .icon .wrap-img {
        width: 80px;
        height: 80px;
    }

    .TopBanner .wrap-icons .icon .text {
        min-width: auto;
        font-size: 0.875rem;
    }
}
