.SectionCard {
    margin-top: 2rem;
}

.SectionCard .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.SectionCard .content .head .wrap-img {
    margin-top: 30px;
    width: 300px;
    height: 380px;
}

.SectionCard .content .head .wrap-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.SectionCard .content .body {
    margin-top: 20px;
}

.SectionCard .content .body .text-box {
    margin-top: 20px;
}

.SectionCard .content .body .text-box p {
    font-size: 16px;
    color: #4f4f4f;
}

.SectionCard .content .body .text-box p:not(:first-child) {
    margin-top: -10px;
}

.SectionCard .content .body .steps-list ol {
    margin-left: 1.125rem;
    color: #4f4f4f;
    font-weight: 500;
}

.SectionCard .content .body .steps-list ol li {
    color: #4f4f4f;
    font-weight: 600;
}

@media all and (min-width: 768px) {
    .SectionCard .content .head .wrap-img {
        margin-top: 0px;
    }

    .SectionCard .content {
        text-align: left;
        flex-direction: row;
    }

    .SectionCard.reversed .content {
        flex-direction: row-reverse;
    }

    .SectionCard .content .body {
        margin-right: 0px;
        margin-left: 40px;
    }

    .SectionCard.reversed .content .body {
        margin-right: 40px;
        margin-left: 0px;
    }

    .SectionCard .content > * {
        flex: 1;
    }
}

@media all and (min-width: 992px) {
    .SectionCard .content .head .wrap-img.md {
        width: 400px;
        height: 400px;
    }

    .SectionCard .content .head .wrap-img.lg {
        width: 400px;
        height: 500px;
    }
}

@media all and (min-width: 1200px) {
    .SectionCard .content .head .wrap-img.md {
        width: 450px;
        height: 400px;
    }
}
