#Sidebar {
    position: fixed;
    min-width: 220px;
    height: 100%;
    background: #f5f5f5;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.25);
    z-index: 2;
}

#Sidebar .wrapper {
    margin-top: 8px;
}

#Sidebar .wrapper .navbar-list {
    list-style: none;
}

#Sidebar .wrapper .navbar-list .navbar-item {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}

#Sidebar .wrapper .navbar-list .navbar-item,
#Sidebar .wrapper .navbar-list .navbar-dropdown {
    font-weight: 500;
    letter-spacing: 0.8px;
    padding: 10px 12px;
    font-size: 16px;
    transition:  all 300ms;
}

#Sidebar .wrapper .navbar-list .navbar-item:hover,
#Sidebar .wrapper .navbar-list .navbar-dropdown:hover  {
    background: #8d9498;
    background: #fff;
}

#Sidebar .wrapper .navbar-list .navbar-item .navbar-link,
#Sidebar .wrapper .navbar-list .navbar-dropdown .navbar-link {
    color: #212529;
    margin-left: 8px;
    text-decoration: none;
}


#Sidebar .wrapper .navbar-list .navbar-dropdown {
    position: relative;
}

#Sidebar .wrapper .navbar-list .navbar-dropdown-list  {
    /* position: absolute; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    transition: all 300ms;
}

#Sidebar .wrapper .navbar-list .navbar-dropdown-list.hide  {
    display: none;
}

#Sidebar .wrapper .navbar-list .navbar-dropdown-list.show  {
    display: block;
}

#Sidebar .wrapper .navbar-list .navbar-dropdown-list .navbar-item:first-child {
    margin-top: 8px;
}
