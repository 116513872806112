.Topbar {
	margin-top: 20px;
	padding: 8px 20px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

.Topbar .Topbar-SwitchBtn {
	width: 100px;
	height: 40px;
	margin-right: 20px;
}

.Topbar .Topbar-SwitchBtn .switch-on {
	background-color: var(--primaryYellowColor) !important;
}

.Topbar .Topbar-SwitchBtn .switch-off {
	background-color: var(--primaryBlueColor) !important;
}

.Topbar .box2 {
	display: flex;
	align-items: center;
}

.Topbar .box2 .language-globe {
	font-size: 1.5rem;
}

.Topbar.hero .box2 .language-globe {
	color: var(--primaryYellowColor);
}

.Topbar.angel .box2 .language-globe {
	color: var(--primaryBlueColor);
}

.Topbar .box1 .bars-btn {
	border: none;
	outline: none !important;
	font-size: 1.5rem;
	background-color: transparent;
}

.Topbar.hero .box1 .bars-btn {
	color: var(--primaryYellowColor);
}

.Topbar.angel .box1 .bars-btn {
	color: var(--primaryBlueColor);
}
