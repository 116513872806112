.ProjectCard {
	cursor: pointer;
	width: 280px;
	background-color: #fafafa;
	border-radius: 8px;
	margin: auto;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	transition: all 300ms ease-in-out;
}

.ProjectCard:hover .wrapper-title {
	text-decoration: underline;
}

.ProjectCard .top-img {
	border-radius: 8px;
	width: 100%;
	height: 165px;
}

.ProjectCard .top-img img {
	border-radius: 8px 8px 0px 0px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.ProjectCard .content-box {
	padding: 20px 14px 30px 14px;
}

.ProjectCard .content-box .text {
	margin-top: 0.875rem;
}

.ProjectCard .content-box .progress {
	height: 0.75rem;
	margin: 10px 0px;
	background-color: #4f4f4f;
}

@media all and (min-width: 400px) {
	.ProjectCard {
		width: 330px;
	}

	.ProjectCard .top-img {
		height: 185px;
	}
}

@media all and (min-width: 576px) {
	.ProjectCard {
		width: 380px;
	}

	.ProjectCard .top-img {
		height: 200px;
	}
}
