/* StyleSheet */
@font-face {
	font-family: MontserratRegular;
	src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--primaryBlueColor: #3b918f;
	--primaryYellowColor: #ec972f;
	--secondaryBlueColor: #2fa398;
	--primaryGreyColor: #666666;
	--halfWhite: #f2f1f0;
	--spanishGrey: #909090;
}

body {
	font-family: MontserratRegular;
	overflow-x: hidden;
}

.comment-reply-button {
	margin-left: 75%;
}

/*  */
/* Start Of => Navbar Component */
/*  */
#navbar {
	padding: 12px 10px;
	background: #f5f5f5;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.bar {
	width: 100vw;
	height: 3px;
	background: dodgerblue;
}

#navbar .wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#navbar .wrapper .wrap-logo {
	width: 100px;
	height: 40px;
}

#navbar .wrapper .wrap-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#navbar .loginBtn {
	font-weight: bolder !important;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	color: darkblue;
	transition: all 300ms ease;
}

#navbar .loginBtn:hover {
	color: darkblue !important;
}
/*  */
/* End Of => Navbar Component */
/*  */

/*  */
/* Start Of => Navigation Component */
/*  */
#navigation {
	padding: 12px 10px;
	background: #f5f5f5;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#navigation .wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#navigation .wrapper .wrap-logo {
	width: 100px;
	height: 40px;
}

#navigation .wrapper .wrap-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#navigation .wrapper .wrap-list .custom-navbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

#navigation .wrapper .wrap-list .custom-navbar .navbar-item {
	margin: 0rem 1rem;
}

#navigation .wrapper .wrap-list .custom-navbar .navbar-item:last-child {
	margin: 0px;
	margin-left: 1rem;
}

#navigation .wrapper .wrap-list .custom-navbar .navbar-item .navbar-link {
	margin-top: 1rem;
}

.custom-navbar .navbar-item {
	list-style: none;
}

.custom-navbar .navbar-item .navbar-link {
	display: block;
	margin-top: 0.5rem;
	text-decoration: none;
	text-transform: uppercase;
	color: #7b7b7b;
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: 0.5px;
	padding: 0.2em 1em;
}

.custom-navbar .navbar-item .navbar-link.active {
	background: #aaa;
	color: #192bc2;
}

.bars {
	cursor: pointer;
}

.mobile-navbar {
	display: none;
}
/*  */
/* End Of => Navigation Component */
/*  */

/*  */
/* Start Of => Login Component */
/*  */
#login .row {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

#login .wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 5px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#login .wrapper .wrap-img {
	width: 200px;
	height: 80px;
}

#login .wrapper .wrap-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

#login .wrapper .wrap-form {
	margin: 20px 0px;
}

#login .wrapper .input-field {
	text-align: center;
}

#login .wrapper .divider {
	width: 50%;
	height: 1px;
	background: #ccc;
}

#login .wrapper .wrap-otherBtns {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#login .wrapper .wrap-otherBtns .btn-other {
	margin: 4px 0px;
	font-size: 0.875rem;
}
/*  */
/* End Of => Login Component */
/*  */

/*  */
/* Start Of => Register Component */
/*  */
#register {
	margin: 50px 0px;
}

#register .wrapper .wrap-personal,
#register .wrapper .wrap-private {
	margin-top: 50px;
}

#register .wrapper .wrap .title {
	text-transform: capitalize;
	font-weight: bold;
	letter-spacing: 0.5px;
}

#register .wrapper .wrap .text-main {
	font-weight: bold;
	font-size: 1.125rem;
}

#register .wrapper .wrap .text-aside {
	color: #7b7b7b;
	margin-top: -12px;
	font-style: italic;
}

.single-comment-body {
	display: flex;
	align-content: space-between;
}

#register .wrapper .wrap .text-aside span {
	color: dodgerblue;
	font-weight: bold;
}

#register .wrapper .wrap .fields {
	margin-top: 30px;
}

#register .wrapper .wrap .fields label {
	text-transform: uppercase;
	color: #7b7b7b;
	font-weight: bold;
	font-size: 0.875rem;
}

#register .wrapper .wrap.wrap-btn {
	margin-top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}
/*  */
/* End Of => Register Component */
/*  */

/*  */
/* Start Of => Profile Component */
/*  */
#profile {
	min-height: 100vh;
}
/*  */
/* End Of => Profile Component */
/*  */

.bg-white {
	background-color: #fff;
}
.bg-gray {
	background-color: #e4e4e4;
}

/* Meida Quries */
@media all and (min-width: 768px) {
	/* Register Component */
	#register .wrapper .wrap .fields {
		display: flex;
		flex-wrap: wrap;
	}

	#register .wrapper .wrap .fields .form-group {
		margin-right: 20px;
	}
}
