.MainContent .wrap-title .title {
    color: var(--primaryBlueColor);
    font-weight: bold;
    font-size: 4rem;
}

.MainContent .wrap-title .title:last-child {
    margin-top: -30px;
}

.MainContent .wrap-content {
    color: var(--spanishGrey);
    font-size: 1.125rem;
    font-weight: bold;
}

@media all and (max-width: 767px) {
    .MainContent .wrap-title .title {
        text-align: center;
        font-size: 3.5rem;
    }

    .MainContent .wrap-title .title:last-child {
        margin-top: -25px;
    }

    .MainContent .wrap-content {
        margin: auto;
        text-align: center !important;
    }
}

@media all and (max-width: 575px) {
    .MainContent .wrap-title .title {
        font-size: 2.5rem;
    }

    .MainContent .wrap-title .title:last-child {
        margin-top: -20px;
    }

    .MainContent .wrap-content {
        width: 80%;
        margin: auto;
        font-size: 1rem;
    }
}

@media all and (max-width: 400px) {
    .MainContent .wrap-title .title {
        font-size: 2rem;
    }
}
