.PrimaryBtn {
	display: inline-block;
	text-decoration: none;
	text-transform: uppercase;
	text-decoration: none !important;
	text-align: center;
	border-radius: 20px;
	outline: none !important;
	border: none;
	color: #fff;
	font-weight: 600;
	transition: all 300ms ease;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.PrimaryBtn.yellow {
	background-color: var(--primaryYellowColor);
}

.PrimaryBtn.blue {
	background-color: var(--primaryBlueColor);
}

.PrimaryBtn.yellow:hover {
	color: #fff;
}

.PrimaryBtn.transparent {
	border: 1px solid #fff;
	color: #fff;
	background-color: transparent;
}

.PrimaryBtn.transparentYellow {
	border: 1px solid #ec972f;
	color: #ec972f;
	background-color: transparent;
}

.PrimaryBtn.transparentBlue {
	text-align: center;
	border: 1px solid var(--primaryBlueColor);
	color: var(--primaryBlueColor);
	background-color: transparent;
}

.PrimaryBtn.disabled {
	text-align: center;
	border: 1px solid #6c757d;
	color: #fff;
	background-color: #6c757d;
}

.PrimaryBtn .icon {
	font-size: 16px;
	margin-left: 8px;
}
