#ForgotPassword .container {
    position: relative;
}

#ForgotPassword .container .row {
    align-items: center;
}

#ForgotPassword .form-wrapper {
    margin-top: 30px;
    margin-bottom: 50px;
}

#ForgotPassword .form-wrapper form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ForgotPassword .form-wrapper .title {
    font-weight: bold;
    margin-bottom: 20px;
}

#ForgotPassword .form-wrapper button {
    margin-top: 20px;
}
