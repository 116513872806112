.CampaignCard {
    max-width: 380px;
    border: 1px solid #aaa;
    box-shadow: 0px 2px 4px rgba(0,0,0,.25);
    box-sizing: border-box;
}

.CampaignCard .head {
    width: 100%;
    max-height: 250px;
}

.CampaignCard .head img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CampaignCard .body {
    padding: 15px 10px;
}

.CampaignCard .body .title {
    letter-spacing: 0.25px;
}

.CampaignCard .body .text {
    color: #747373;
}

.CampaignCard .body .link {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: #AB29B5;
    font-size: 18px;
}
