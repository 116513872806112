.LocationAutoComplete {
    position: relative;
}

.LocationAutoComplete .LocationAutoComplete-List {
    position: absolute;
    left: 0;
    top: 100%;
    padding-right: 5px;
    margin: 0;
    width: 100%;
    max-height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.LocationAutoComplete
    .LocationAutoComplete-List
    .LocationAutoComplete-ListItem {
    margin: 0;
    padding: 4px;
    width: 100%;
    font-size: 12px;
    color: #000 !important;
}
