.CharityWrapper {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CharityWrapper form label {
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.custom-card {
    width: 45%;
}

.main-image {
    width: 100%;
    height: 500px;
}

@media all and (min-width: 767px) {
    .CharityWrapper form {
        flex: 0.5;
    }    
}

.story {
    height: 300px;
    overflow: scroll;
    scroll-behavior: auto;
    border-radius: 5px;
    border-width: 1px;
    padding: 10px;
    padding-left: 30;
    border-style: solid;
}