.shift-detail-Description{
    overflow-y: auto;
    max-height: 90px;
}
.shift-detail-task{
    overflow-y: auto;
    height: 39%;
    
}


.shift-comment-sender{
    /* max-width:294px; */
    margin: 0 auto;
    height: 65.05px;
    overflow-y: auto;
    background: #dadada;
border-radius: 10px;
clip-path: polygon(0% 0%, 97% 0, 97% 100%, 83% 100%, 96% 73%, 100% 100%, 0% 100%);
}

.shift-comment-receiver{
    color: white;
    
    height: 65.05px;
    overflow-y: auto;
    background: #3796F6;
border-radius: 10px;
clip-path: polygon(4% 0, 100% 0, 100% 83%, 100% 100%, 8% 100%, 0 100%, 4% 74%);
}

.media-section {
    background-color: var(--primaryColor);
}

.chat-not-clock-in {
    text-align: center;
}

.chat-div{
    /* position: absolute;
    bottom: 0; */
}
.chat-subdiv{
    position: absolute;
    bottom: 0;
}
.chat-msg-input{
    /* width: 100%; */
    height: 40.96px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 2px solid white;
}

.chat-send-svgicon{
    position: absolute;
    top: 16%;
    right: 10px;
     cursor: pointer;
}
.chat-arrow{
    
}
.chat-arrow-forward{
    position: absolute;
    cursor: pointer;
    top: 16%;
}
.time-span{
    /* max-width:294px; */
    margin: 0 auto;
}

svg {
    fill:#3796F6;
  }

/* Media query shift detail page */

@media only screen and (max-width : 768px) {
    .media-section{
        margin: 3rem auto;
    }
}
