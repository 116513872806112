.PrimaryTitle {
    text-align: center;
}

.PrimaryTitle.WithImg {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PrimaryTitle .img-box {
    width: 120px;
    height: 100px;
    margin-right: 10px;
}

.PrimaryTitle .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.PrimaryTitle .title-box .title {
    color: var(--primaryBlueColor);
    font-weight: bold;
}

.PrimaryTitle .title-box .sub-title {
    color: #000;
    font-weight: bold;
    margin-top: -10px;
    font-size: 1.2rem;
}

@media all and (max-width: 550px) {
    .PrimaryTitle .title-box .title {
        font-size: 1.75rem;
    }

    .PrimaryTitle .title-box .sub-title {
        font-size: 1.125rem;
    }
}

@media all and (max-width: 420px) {
    .PrimaryTitle .title-box .title {
        font-size: 1.5rem;
    }

    .PrimaryTitle .title-box .sub-title {
        font-size: 1rem;
    }
}
