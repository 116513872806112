.LabelledInput .Label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-left: 2px;
    margin-bottom: -1px;
}

.LabelledInput .InputField {
    display: block;
    width: 250px;
    font-size: 16px;
    padding: 8px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    resize: none;
    background-color: #fff;
}

.LabelledInput .InputField:focus {
    outline-color: var(--primaryYellowColor);
}

@media all and (min-width: 350px) {
    .LabelledInput .Label {
        font-size: 16px;
    }

    .LabelledInput .InputField {
        width: 320px;
    }
}

@media all and (min-width: 500px) {
    .LabelledInput .InputField {
        width: 420px;
    }
}
