.LoginForm {
    margin: 0px 12px 0px;
}

.LoginForm .wrap-input {
    margin-bottom: 16px;
}

.LoginForm .side-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.LoginForm .side-options .forgotLink {
    color: #000;
    font-size: 14px;
}

@media all and (max-width: 450px) {
    .LoginForm .side-options {
        flex-direction: column;
    }
}
