.map {
  position: absolute;
  width: 94% !important;
  height: 94% !important;
}
.modal-header {
  flex-direction: column;
}
.modal-header p span {
  width: 40px;
}

