.Sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	min-height: 100vh;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	overflow-y: auto;
}

.Sidebar.slider {
	left: 0;
	z-index: 999;
	transform-origin: left;
	transform: scaleX(0);
	transition: all 300ms ease;
}

/* Sidebar => Mobile */
.Sidebar .Sidebar-Mobile {
	display: flex;
	justify-content: flex-end;
	padding-top: 12px;
	padding-right: 20px;
}

.Sidebar.hero .Sidebar-Mobile {
	background-color: var(--primaryYellowColor);
}

.Sidebar.angel .Sidebar-Mobile {
	background-color: var(--primaryBlueColor);
}

.Sidebar .Sidebar-Mobile .timeCircle-btn {
	border: none;
	outline: none !important;
	font-size: 1.75rem;
	background-color: transparent;
	color: #fff;
}

/* Sidebar => Head */
.Sidebar .Sidebar-Head {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0px;
}

.Sidebar.hero .Sidebar-Head {
	background-color: var(--primaryYellowColor);
}

.Sidebar.angel .Sidebar-Head {
	background-color: var(--primaryBlueColor);
}

.Sidebar .Sidebar-Head .Head-Avatar {
	width: 150px;
	height: 150px;
}

.Sidebar .Sidebar-Head .name {
	color: #fff;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.5rem;
	margin: 12px 0px;
}

.Sidebar .Sidebar-Head .wrap-options {
	display: flex;
	justify-content: center;
	align-items: center;
}

.Sidebar .Sidebar-Head .wrap-options .option .option-link {
	color: #fff;
	font-size: 1.75rem;
}

.Sidebar .Sidebar-Head .wrap-options .option:nth-child(2) {
	margin: 0px 16px;
}

/* Sidebar => Body */
.Sidebar .Sidebar-Body .navigation {
	margin-top: 16px;
	padding-bottom: 2rem;
}

.Sidebar .Sidebar-Body .navigation .navigation-item {
	padding: 10px 0px;
	border-left: 5px solid transparent;
}

.Sidebar .Sidebar-Body .navigation .navigation-item .navigation-link {
	display: flex;
	align-items: center;
	width: 100%;
	padding-left: 16px;
	color: #222;
	text-decoration: none;
}

.Sidebar
	.Sidebar-Body
	.navigation
	.navigation-item
	.navigation-link
	.item-icon {
	font-size: 1.5rem;
}

.Sidebar
	.Sidebar-Body
	.navigation
	.navigation-item
	.navigation-link
	.item-text {
	margin-left: 12px;
	font-size: 1.125rem;
	text-transform: uppercase;
}

.Sidebar.hero .Sidebar-Body .navigation .navigation-item:hover {
	border-left: 5px solid var(--primaryYellowColor);
    background-color: #dadedf;
}
.Sidebar.angel .Sidebar-Body .navigation .navigation-item:hover {
	border-left: 5px solid var(--primaryBlueColor);
    background-color: #dadedf;
}
