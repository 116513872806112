.CharityWrapper {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CharityWrapper form label {
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.custom-card {
    width: 40%;
}

.main-image {
    width: 100%;
    height: 500px;
}

.text-input {
    width: 700px;
}

.btn-with-margin-left {
    margin-left: 30px;
}

.li-space {
    margin-left: 5px;
}

.error-list {
    color: red;
}

.image-fitting {
    height: 400px;
    width: 400px;
}

.center-items {

    align-items: center;
}

@media all and (min-width: 767px) {
    .CharityWrapper form {
        flex: 0.5;
    }    
}
