.NationalityDropdown {
    display: flex;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
}

.NationalityDropdown select {
    display: block;
    color: #222;
    background-color: #fff;
    width: calc(100% - 43px); 
    border: none;
    outline: none;
    padding: 8px 14px;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid #979797;
    border-radius: 10px 0px 0px 10px;
    background: transparent;
    -webkit-appearance: none;
}

.NationalityDropdown .icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    padding: 0px 14px;
    border: 1px solid #979797;
    border-left: none;
    border-radius: 0px 10px 10px 0px;
    color: #979797;
    background: transparent;
    z-index: 1;
}
